import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditLogo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");

  const handleCancel = () => {
    navigate("/admin/logo");
  };

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get(`/api_w/logo/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const logo = response.data.data;
        setTitle(logo.title);
        setImageUrl(logo.url);
      } catch (error) {
        toast.error(
          "Error fetching logo data" +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchLogoData();
  }, [id]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_w/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        toast.error(
          "Error Upload Gambar: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };
  const handleSubmit = async () => {
    try {
      const url = imageUrl;
      const response = await axios.put(
        `/api_w/logo/${id}`,
        {
          title,
          url,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data);
      toast.success("Berhasil Edit Logo!");
      navigate("/admin/logo");
    } catch (error) {
      toast.error(
        "Error Edit Logo: " + (error.response?.data?.message || error.message)
      );
    }
  };
  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          id="title"
          name="title"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="Judul"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Gambar
        </label>
        <img src={imageUrl} alt="title" className="h-auto w-auto" />
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          className="w-full rounded border px-3 py-2 dark:text-white"
          onChange={handleFileChange}
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 5MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="rounded bg-green-600 py-2 px-4 font-bold text-white"
        >
          Edit
        </button>
      </div>
    </form>
  );
};
export default EditLogo;
