import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditCarousel = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [type, setType] = useState("image");
  const [imageUrls, setImageUrls] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [link, setLink] = useState();
  const { id } = useParams();
  const [previewUrls, setPreviewUrls] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleCancel = () => {
    navigate("/admin/carousel");
  };

  const handleFileChange = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrls((prevUrls) => {
        const newUrls = [...prevUrls];
        newUrls[index] = previewUrl;
        return newUrls;
      });

      setSelectedFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles[index] = file;
        return newFiles;
      });

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_w/upload/multiple`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data[0]?.Location;
        if (uploadedImageUrl) {
          setImageUrls((prevUrls) => {
            const newUrls = [...prevUrls];
            newUrls[index] = uploadedImageUrl;
            return newUrls;
          });
        } else {
          toast.error("Error: Unable to retrieve image URL from response.");
        }
      } catch (error) {
        toast.error(
          "Error Upload Gambar: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const response = await axios.get(`/api_w/carousels/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const carousel = response.data.data;
        setTitle(carousel.title);
        setType(carousel.type);
        const urls = carousel.url ? carousel.url.split(",") : [];
        if (urls.length < 2) {
          urls.push("");
        }
        setImageUrls(urls);
        setPreviewUrls(urls);
        setVideoUrl(carousel.type === "video" ? carousel.url : "");
        setLink(carousel.link);
      } catch (error) {
        toast.error(
          "Error fetching carousel data" +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchCarouselData();
  }, [id]);

  const handleSubmit = async () => {
    try {
      const url = type === "image" ? imageUrls : videoUrl;
      const response = await axios.put(
        `/api_w/carousels/${id}`,
        {
          title,
          url,
          type,
          link,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data);
      toast.success("Berhasil Edit Carousel!");
      navigate("/admin/carousel");
    } catch (error) {
      toast.error(
        "Error Edit Carousel: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          id="title"
          name="title"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="Judul"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold text-gray-800 dark:text-white">
          Tipe
        </label>
        <select
          id="type"
          name="type"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="image">Image</option>
          <option value="video">Video</option>
        </select>
      </div>
      {type === "image" ? (
        <div className="mb-4">
          <label className="text-md block font-bold text-gray-800 dark:text-white">
            Gambar
          </label>
          <div className="mt-2 grid grid-cols-2 gap-4">
            {previewUrls.map((url, index) => (
              <div
                key={index}
                className={`relative flex items-center justify-center ${
                  index === 0 ? "h-60 w-full" : "h-60 w-60"
                } bg-gray-200 border border-gray-400 rounded-md cursor-pointer`}
              >
                {url ? (
                  <img
                    src={url}
                    alt="title"
                    className="h-full w-full object-cover rounded-md"
                  />
                ) : (
                  <span className="text-gray-600 text-center">
                    {index === 0
                      ? "Klik untuk upload gambar tampilan desktop"
                      : "Klik untuk upload gambar tampilan mobile"}
                  </span>
                )}
                <input
                  type="file"
                  id={`img-${index}`}
                  name={`img-${index}`}
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e) => handleFileChange(e, index)}
                />
              </div>
            ))}
          </div>
          <span className="ml-1 mt-2 block text-xs text-gray-600">
            *Max size: 5MB, Format: .png, .jpg, .jpeg, .svg, .webp
          </span>
        </div>
      ) : (
        <div className="mb-4">
          <label className="text-md block font-bold text-gray-800 dark:text-white">
            URL Video
          </label>
          <input
            type="text"
            id="videoUrl"
            name="videoUrl"
            className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            placeholder="URL Video"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </div>
      )}
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold text-gray-800 dark:text-white">
          Link
        </label>
        <input
          type="url"
          id="link"
          name="link"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-green-600 py-2 px-4 font-bold text-white"
          onClick={handleSubmit}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default EditCarousel;
