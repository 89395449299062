import React from "react";
import InputFields from "./InputFields";

const FormSchool = ({ schoolContact, handleChange, update }) => {
  if (!schoolContact) return null;

  return (
    <div>
      <form>
        <InputFields
          label="Nama Sekolah"
          name="school_name"
          value={schoolContact.school_name || "Jogja Madrasah Digital"}
          onChange={handleChange}
          readOnly={!update}
        />
        <InputFields
          label="Alamat"
          name="address"
          value={
            schoolContact.address ||
            "Jl. Sukonandi No.8, Semaki, Kec. Umbulharjo,Kota Yogyakarta, Daerah Istimewa Yogyakarta,55166"
          }
          onChange={handleChange}
          readOnly={!update}
        />
        <InputFields
          label="Instagram"
          name="instagram"
          value={
            schoolContact.instagram ||
            "https://www.instagram.com/jogjamadrasahdigital"
          }
          onChange={handleChange}
          readOnly={!update}
        />
        <InputFields
          label="Youtube"
          name="youtube"
          value={
            schoolContact.youtube ||
            "https://www.youtube.com/@jogjamadrasahdigital"
          }
          onChange={handleChange}
          readOnly={!update}
        />
        <InputFields
          label="WhatsApp"
          name="whatsapp"
          value={schoolContact.whatsapp || "6287739532870"}
          onChange={handleChange}
          readOnly={!update}
        />
        <InputFields
          label="Link Maps"
          name="link_maps"
          value={
            schoolContact.link_maps ||
            "https://maps.app.goo.gl/tZnXmodv7gHNoG9V9"
          }
          onChange={handleChange}
          readOnly={!update}
        />
        <InputFields
          label="Koordinat Maps"
          name="coordinates"
          value={schoolContact.coordinates || "-"}
          onChange={handleChange}
          readOnly={!update}
        />
      </form>
    </div>
  );
};

export default FormSchool;
