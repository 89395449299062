import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function CardVideo() {
  const [videos, setVideos] = useState([]);

  const defaultVideos = [
    {
      id: 1,
      url: "https://www.youtube.com/embed/QetUSIcVFkM?si=HO9yE_p6UF9fTxLC",
      createdAt: "Jumat, 2 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
    {
      id: 2,
      url: "https://www.youtube.com/embed/qhNE9THdIDk?si=fyrKdztkc_3tVkeN",
      createdAt: "Jumat, 2 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
  ];

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api_w/galleries?type=video`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Jika data dari API kosong, gunakan data default
        if (response.data.data && response.data.data.length > 0) {
          setVideos(response.data.data);
        } else {
          setVideos(defaultVideos);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setVideos(defaultVideos);
      }
    };

    fetchVideoData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const renderMedia = (videoUrl) => {
    const youtubeMatch = videoUrl.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = videoUrl.match(
      /drive\.google\.com\/file\/d\/([^\/]+)\//
    );

    let videoSrc = videoUrl;
    if (youtubeMatch) {
      videoSrc = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoSrc = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }

    return (
      <iframe
        className="rounded-md md:w-[350px] h-[200px] w-[100%]"
        src={videoSrc}
        title="Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-xl">
        <span className="border-b-2 border-green-600 font-bold hover:cursor-pointer hover:text-green-700">
          Video
        </span>
      </h3>
      <div className="mt-5">
        {videos.map((video) => (
          <div
            key={video.id}
            className="mt-6 flex flex-col md:flex-row shadow-md"
          >
            <div className="md:mr-4">{renderMedia(video.url)}</div>
            <div className="flex flex-col">
              <h4 className="mt-2 font-bold text-xl hover:text-green-700">{video.title}</h4>
              <p className="text-gray-500 mb-4 mt-2">
                {formatDate(video.createdAt)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardVideo;
