import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function TableUser() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api_w/users", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setUsers(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchUsers();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-user/${id}`);
  };

  return (
    <div>
      <table className="w-full bg-white">
        <thead className="bg-green-700 text-sm uppercase text-white leading-normal">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Foto</th>
            <th className="px-6 py-3 text-center">Name</th>
            <th className="px-6 py-3 text-center">Username / Email</th>
            <th className="px-6 py-3 text-center">Phone</th>
            <th className="px-6 py-3 text-center">Role</th>
            {role === "superadmin" && (
              <th className="px-6 py-3 text-center">Aksi</th>
            )}
          </tr>
        </thead>
        <tbody className="text-sm">
          {users.map((item, index) => (
            <tr className="border border-gray-200 hover:bg-gray-100">
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="px-6 py-3 flex justify-center">
                <img alt="" src={item.profile_pict} className="h-10 w-10" />
              </td>
              <td className="px-6 py-3 text-center">{item.name}</td>
              <td className="px-6 py-3 text-center">
                {item.username} / {item.email}
              </td>
              <td className="px-6 py-3 text-center">{item.phone || "-"}</td>
              <td className="px-6 py-3 text-center">{item.role}</td>
              {role === "superadmin" && (
                <td className="px-6 py-3 text-center">
                  <button
                    onClick={() => handleEditClick(item.id)}
                    className="transform text-blue-400 hover:scale-110 hover:text-blue-600"
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableUser;
