import React, { useEffect, useState } from "react";
import background from "../../../assets/img/background.png";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
import logo from "../../../assets/img/logo1.png";

function EducationWebsite() {
  const [summary, setSummary] = useState({});
  const [logos, setLogos] = useState([]);

  const getSummary = async () => {
    try {
      let response = await axios.get("_api/main/stats");
      setSummary(response.data.summary || {});
      console.log(setSummary);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getSummary();
  }, []);

  const getLogos = async () => {
    try {
      let response = await axios.get("_api/main/stats");
      setLogos(response.data.items || []);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getLogos();
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="flex flex-col items-center text-center p-4"
    >
      <h1 className="text-xl md:text-2xl font-bold mb-4">
        All-In-One <span className="text-[#006316]">Education Website</span>
      </h1>
      <p className="text-md max-w-2xl mb-8">
        Website Edukasi dengan berbagai macam fitur yang dapat digunakan oleh
        guru dan murid dalam kegiatan belajar mengajar dimana saja dan kapan
        saja
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-4xl">
        <div className="bg-gray-600 bg-opacity-50 p-4 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-white text-left">Guru</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-green-500 p-4 rounded-lg shadow relative">
              <h3 className="text-xs text-white absolute top-2 left-2">
                Aktif
              </h3>
              <p className="text-white font-semibold text-center text-2xl mt-4">
                {summary.teachers_7d}
              </p>
            </div>
            <div className="bg-green-500 p-4 rounded-lg shadow relative">
              <h3 className="text-xs text-white absolute top-2 left-2">
                Total
              </h3>
              <p className="text-white font-semibold text-center text-2xl mt-4">
                {summary.teachers}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gray-600 bg-opacity-50 p-4 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-white text-left">
            Sekolah
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-green-500 p-4 rounded-lg shadow relative">
              <h3 className="text-xs text-white absolute top-2 left-2">
                Aktif
              </h3>
              <p className="text-white font-semibold text-center text-2xl mt-4">
                {summary.schools_7d}
              </p>
            </div>
            <div className="bg-green-500 p-4 rounded-lg shadow relative">
              <h3 className="text-xs text-white absolute top-2 left-2">
                Total
              </h3>
              <p className="text-white font-semibold text-center text-2xl mt-4">
                {summary.schools}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-600 bg-opacity-50 p-4 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-white text-left">
            Siswa
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-green-500 p-4 rounded-lg shadow relative">
              <h3 className="text-xs text-white absolute top-2 left-2">
                Aktif
              </h3>
              <p className="text-white font-semibold text-center text-2xl mt-4">
                {summary.students_7d}
              </p>
            </div>
            <div className="bg-green-500 p-4 rounded-lg shadow relative">
              <h3 className="text-xs text-white absolute top-2 left-2">
                Total
              </h3>
              <p className="text-white font-semibold text-center text-2xl mt-4">
                {summary.students}
              </p>
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-xl md:text-2xl font-bold mb-4 text-[#006316] mt-8">
        {summary.schools}+ Madrasah
        <span className="text-[#585858]"> telah Begabung!</span>
      </h1>
      <Swiper
        style={{
          "--swiper-navigation-color": "#D1D1D1",
          "--swiper-navigation-size": "35px",
        }}
        modules={[Navigation, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        className="w-full max-w-4xl"
      >
        {logos.map((item, index) => (
          <SwiperSlide>
            <div key={index} className="flex flex-col items-center text-center">
              <div className="border border-gray-300 p-2 md:h-28 md:w-28 h-20 w-20 flex items-center justify-center">
                {item.icon === "" ? (
                  <img
                    className="md:h-24 md:w-24 h-16 w-16"
                    src={logo}
                    alt="logo-school"
                  />
                ) : (
                  <img
                    className="md:h-24 md:w-24 h-16 w-16"
                    src={item.icon}
                    alt="logo-school"
                  />
                )}
              </div>
              <p className="mt-2 md:text-sm text-xs">{item.name}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default EducationWebsite;
