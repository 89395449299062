import React, { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import Links from "./Links";
import routes from "../../../routes";
import logoJMD from "../../../assets/img/logo-jmd.png";
import logokemenag from "../../../assets/img/logo-kemenang.png";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import axios from "axios";
import { toast } from "react-toastify";

const Sidebar = ({ open, onClose }) => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get("/api_w/logo", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const logoData = response.data.data || [];

        // Default logo
        const defaultLogos = [
          { id: 1, url: logokemenag },
          { id: 2, url: logoJMD },
        ];

        // Check if the API response contains logos with ID 1 and 2
        const logosDefaults = defaultLogos.map((defaultLogo) => {
          const dataLogo = logoData.find((logo) => logo.id === defaultLogo.id);
          return dataLogo || defaultLogo;
        });

        setLogos(logosDefaults);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setLogos([
          { id: 1, url: logokemenag },
          { id: 2, url: logoJMD },
        ]);
      }
    };

    fetchLogoData();
  }, []);

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[20px] flex items-center justify-center`}>
        <Link to={"/admin"} className="flex items-center ">
          {logos.map((logo) => (
            <Link to="/">
              <img
                className="h-11 w-auto mr-2 mt-4"
                src={logo.url}
                alt="Logo"
              />
            </Link>
          ))}
          {/* <img
            className="mr-2 h-12 w-auto mt-4"
            src={logokemenag}
            alt="Logo kemenag"
          />
          <img className="h-10 w-auto mr-2 mt-4" src={logoJMD} alt="Logo JMD" /> */}
          <div className="flex flex-col mt-5 ml-1 text-navy-700 dark:text-white items-center">
            <span className="text-[20px] font-bold uppercase">
              Jogja Madrasah
            </span>
            <span className="text-[20px] font-bold uppercase">Digital</span>
          </div>
        </Link>
      </div>

      <div class="mt-[28px] mb-7 h-px bg-gray-300 dark:bg-white/30" />

      <ul
        className="mb-auto pt-1"
        style={{
          overflowY: "auto",
          paddingRight: "17px",
          maxHeight: "calc(100vh - 188px)",
          scrollbarWidth: "thin",
        }}
      >
        <Links routes={routes} />
        <Menu />
      </ul>
    </div>
  );
};

export default Sidebar;
