import React, { useState } from "react";

const cards = [
  {
    id: 1,
    className: "Kelas 7",
    categories: ["All", "7A", "7B", "7C"],
    students: [
      { id: 1, name: "Siswa 1", class: "7A" },
      { id: 2, name: "Siswa 2", class: "7B" },
      { id: 3, name: "Siswa 3", class: "7C" },
      { id: 4, name: "Siswa 3", class: "7C" },
      { id: 5, name: "Siswa 3", class: "7C" },
      { id: 6, name: "Siswa 3", class: "7C" },
      { id: 7, name: "Siswa 3", class: "7C" },
      { id: 8, name: "Siswa 3", class: "7C" },
    ],
  },
  {
    id: 2,
    className: "Kelas 8",
    categories: ["All", "8A", "8B", "8C"],
    students: [
      { id: 9, name: "Siswa 4", class: "8A" },
      { id: 10, name: "Siswa 5", class: "8B" },
      { id: 11, name: "Siswa 6", class: "8C" },
      { id: 12, name: "Siswa 4", class: "8A" },
      { id: 13, name: "Siswa 4", class: "8A" },
      { id: 14, name: "Siswa 4", class: "8A" },
      { id: 15, name: "Siswa 4", class: "8A" },
      { id: 16, name: "Siswa 4", class: "8A" },
    ],
  },
  {
    id: 3,
    className: "Kelas 9",
    categories: ["All", "9A", "9B", "9C"],
    students: [
      { id: 17, name: "Siswa 7", class: "9A" },
      { id: 18, name: "Siswa 8", class: "9B" },
      { id: 19, name: "Siswa 9", class: "9C" },
    ],
  },
];

function Presensi() {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <div className="relative flex flex-col items-center justify-center overflow-visible rounded-2xl bg-gradient-to-r from-green-500 to-green-300 p-5">
        <div className="flex p-5 text-center text-[30px] font-bold text-white">
          Presensi Jogja Madrasah Digital
        </div>
        <div className="relative mb-5 p-3 text-justify text-[18px] font-normal leading-normal tracking-normal text-white">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque, at
          voluptatem obcaecati delectus tempore fuga perspiciatis id, voluptates
          sequi magnam ducimus ab temporibus ex esse iure praesentium, repellat
          vero velit. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Obcaecati veniam harum itaque fugit dicta expedita amet a tenetur,
          debitis culpa, vitae sint laudantium dolores magnam ipsum molestias
          est sunt accusamus. Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Dicta ab labore dolorem, temporibus quod, omnis doloremque, quae
          eum adipisci pariatur sit libero minima quas earum? Provident pariatur
          iste laudantium sunt!
        </div>
        <div className="hidden md:block">
          <br /> <br /> <br /> <br /> <br /> <br /> <br />
        </div>
        {/* Mobile devices */}
        <div className="grid grid-cols-1 gap-6 p-5 sm:grid-cols-2 md:hidden lg:grid-cols-3">
          {cards.map((card) => (
            <div
              key={card.id}
              className="relative overflow-visible rounded-lg bg-white p-5 shadow-md"
              style={{ marginTop: "1rem" }}
            >
              <h3 className="mb-2 text-xl font-bold text-green-800">
                {card.className}
              </h3>
              <div className="flex flex-wrap">
                {card.categories.map((category) => (
                  <div key={category}>
                    <button
                      className={`${
                        selectedCategory === category
                          ? "border-2 border-green-500 bg-green-500 text-white"
                          : "border-2 border-gray-300 text-gray-700"
                      } mr-2 mb-2 rounded-md px-2 py-1`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category}
                    </button>
                  </div>
                ))}
              </div>
              <div className="mt-2 grid max-h-52 grid-cols-1 gap-4 overflow-y-auto pr-2">
                {card.students
                  .filter(
                    (student) =>
                      student.class === selectedCategory ||
                      selectedCategory === "All"
                  )
                  .map((student) => (
                    <div
                      key={student.id}
                      className="flex items-center justify-between rounded-lg bg-gray-100 p-2 shadow-md"
                    >
                      <div>
                        <p className="text-base">{student.name}</p>
                        <div>
                          <button className="bg-[#33DB44] px-2 py-1 text-sm text-white">
                            Masuk 07:00
                          </button>
                          <button className="bg-gray-300 px-2 py-1 text-sm text-white">
                            Pulang 15:00
                          </button>
                        </div>
                      </div>
                      <p className="text-sm mb-8">{student.class}</p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Desktop devices */}
      <div className="hidden md:block">
        <div className="grid grid-cols-1 gap-6 p-5 sm:grid-cols-2 lg:grid-cols-3 ">
          {cards.map((card) => (
            <div
              key={card.id}
              className="relative overflow-visible rounded-lg bg-white p-5 shadow-md"
              style={{ marginTop: "-12rem" }}
            >
              <h3 className="mb-2 text-xl font-bold text-green-800">
                {card.className}
              </h3>
              <div className="flex flex-wrap">
                {card.categories.map((category) => (
                  <div key={category}>
                    <button
                      className={`${
                        selectedCategory === category
                          ? "border-2 border-green-500 bg-green-500 text-white"
                          : "border-2 border-gray-300 text-gray-700"
                      } mr-2 mb-2 rounded-md px-2 py-1`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category}
                    </button>
                  </div>
                ))}
              </div>
              <div
                className="mt-2 grid grid-cols-1 gap-4 overflow-y-auto pr-4"
                style={{
                  maxHeight: "calc(100vh - 188px)",
                  scrollbarWidth: "thin",
                }}
              >
                {card.students
                  .filter(
                    (student) =>
                      student.class === selectedCategory ||
                      selectedCategory === "All"
                  )
                  .map((student) => (
                    <div
                      key={student.id}
                      className="flex items-center justify-between rounded-lg bg-gray-100 p-3 shadow-md"
                    >
                      <div>
                        <p className="text-base">{student.name}</p>
                        <div>
                          <button className="mr-10 bg-[#33DB44] px-2 py-1 text-sm text-white">
                            Masuk 07:00
                          </button>
                          <button className="bg-gray-300 px-2 py-1 text-sm text-white">
                            Pulang 15:00
                          </button>
                        </div>
                      </div>
                      <p className="text-sm">{student.class}</p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Presensi;
