import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Sambutan = () => {
  const [sambutan, setSambutan] = useState("");
  const [idSambutan, setIdSambutan] = useState("");
  const [titleSambutan, setTitleSambutan] = useState("");
  const [imageSambutan, setImageSambutan] = useState("");
  const [videoSambutan, setVideoSambutan] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataSamnbutan = async () => {
      try {
        const response = await axios.get(`/api_w/abouts?title=sambutan`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const sambutans = response.data.data[0];
        //data sambutan
        setIdSambutan(sambutans?.id || "");
        setTitleSambutan(sambutans?.title || "");
        setSambutan(sambutans?.body || "");
        setImageSambutan(sambutans?.image_url || "");
        setVideoSambutan(sambutans?.video_url || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchDataSamnbutan();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-sambutan/${id}`);
  };

  const renderVideo = (url) => {
    const youtubeMatch = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = url.match(/drive\.google\.com\/file\/d\/([^\/]+)\//);

    let videoUrl = url;
    if (youtubeMatch) {
      videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }
    return (
      <div className="flex justify-center">
        <iframe
          className="mt-4 w-80 h-48"
          src={videoUrl}
          title="Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };
  return (
    <div className="mx-auto mt-8 p-5">
      <div className="relative mx-auto mb-4 w-full rounded-lg border border-gray-600 bg-gray-200 p-6">
        <h2 className="mb-4 flex items-center justify-center text-center text-xl font-bold uppercase">
          {titleSambutan}
        </h2>
        {imageSambutan && (
          <div className="flex justify-center">
            <img src={imageSambutan} alt="visi-image" className="h-48 w-48" />
          </div>
        )}
        {videoSambutan && renderVideo(videoSambutan)}
        <div className="mt-4 break-words text-justify">{parse(sambutan)}</div>
        <FaEdit
          className="absolute top-2 right-2 h-5 w-5 cursor-pointer text-gray-800"
          onClick={() => handleEditClick(idSambutan)}
        />
      </div>
    </div>
  );
};
export default Sambutan;
