import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex border-b">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-6 py-2 -mb-px border-b-2 transition-colors duration-300 ${
              activeTab === index
                ? "border-green-600 text-green-600"
                : "border-transparent hover:text-green-600 hover:border-green-600 dark:text-white"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-5">{tabs[activeTab] && tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
