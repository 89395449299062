import React, { useEffect, useState } from "react";
import Sambutan from "./Sambutan";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CardBerita from "./CardBerita";
import CardArtikel from "./CardArtikel";

const BeritaTerbaru = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_w/posts`, {
          params: {
            // status: 1,
            category_id: 2,
          },
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const filteredPosts = response.data.data.filter(
          (post) => post.status == 1
        );

        const sortedCards = filteredPosts.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setCards(sortedCards);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateTitle = (title, limit) => {
    if (title.length > limit) {
      return title.substring(0, limit) + "...";
    } else {
      return title;
    }
  };

  return (
    <div className="row flex flex-wrap gap-2">
      <div className="w-full lg:w-[68%]">
        {/* Card Kata Sambutan */}
        <Sambutan />

        {/* Card Berita */}
        <CardBerita />
        {/* Card Artikel */}
        <CardArtikel />
      </div>

      <div className="w-full lg:ml-auto lg:w-[30%] p-4">
        {/* Card Berita Terbaru */}
        <h3 className="headers text-2xl">
          <span className="border-b-2 border-green-500 font-bold font-bold hover:cursor-pointer hover:text-green-700">
            What's New
          </span>
        </h3>
        <div className="mt-4 flex flex-col">
          {cards.slice(0, 3).map((news) => (
            <Link
              key={news.id}
              to={`/berita/${news.slug}`}
              className="mb-4 flex overflow-hidden rounded-lg border shadow-md hover:shadow-lg"
            >
              <img src={news.image} alt="berita" className="h-auto w-36" />
              <div className="flex flex-col justify-between p-3">
                <h4 className="mb-2 text-lg font-semibold">
                  {truncateTitle(news.title, 30)}
                </h4>
                <p className="text-sm text-gray-500">{formatDate(news.date)}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeritaTerbaru;
