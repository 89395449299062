import React, { useEffect, useState } from "react";
import whatsapp from "../../../assets/icon-sosmed/wa.png";
import youtube from "../../../assets/icon-sosmed/yt.png";
import instagram from "../../../assets/icon-sosmed/ig.png";
import axios from "axios";
import { toast } from "react-toastify";

function Sosmed() {
  const [medsos, setMedsos] = useState([]);

  useEffect(() => {
    const fetchKontak = async () => {
      try {
        const response = await axios.get(`/api_w/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const kontak = response.data.data[0];
        setMedsos(kontak);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchKontak();
  }, []);

  return (
    <>
      {/* Desktop devices */}
      <div className="relative flex hidden items-center justify-center bg-green-100 md:block">
        <div className="mx-auto">
          <p className="tracking-0 text-center text-3xl font-semibold italic leading-normal text-green-600 p-8">
            Ikuti Jogja Madrasah Digital di Media Sosial
          </p>
          <div className="flex flex-wrap justify-center gap-20 p-4">
            <div className="flex flex-col items-center justify-center gap-2">
              <a
                href={
                  `https://wa.me/${medsos.whatsapp}` ||
                  "https://wa.me/0811679689"
                }
                target="_blank"
                rel="noopener noreferrer"
                title="WhatsApp"
              >
                <img className="h-16 w-16" alt="whatsapp" src={whatsapp} />
                <div className="text-center text-[16px] leading-[normal] tracking-[0] text-green-700 hover:text-green-600 mt-2">
                  Whatsapp
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <a
                href={
                  medsos.youtube ||
                  "https://www.youtube.com/@jogjamadrasahdigital"
                }
                target="_blank"
                rel="noopener noreferrer"
                title="Youtube"
              >
                <img className="h-16 w-16" alt="youtube" src={youtube} />
                <div className="text-center text-[16px] leading-[normal] tracking-[0] text-green-700 hover:text-green-600 mt-2">
                  Youtube
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <a
                href={
                  medsos.instagram ||
                  "https://www.instagram.com/jogjamadrasahdigital"
                }
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <img className="h-16 w-16" alt="instagram" src={instagram} />
                <div className="text-center text-[16px] leading-[normal] tracking-[0] text-green-700 hover:text-green-600 mt-2">
                  Instagram
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile devices */}
      <div className="relative flex flex-col items-center justify-center bg-blue-100 md:hidden">
        <p className="text-center text-[25px] font-semibold italic leading-[normal] tracking-[0] text-green-700 p-10">
          Ikuti Jogja Madrasah Digital di Media Sosial
        </p>
        <div className="flex flex-wrap justify-center gap-8 p-8">
          <div className="flex flex-col items-center justify-center gap-2">
            <a
              href={
                `https://wa.me/${medsos.whatsapp}` ||
                "https://wa.me/6287739532870"
              }
              target="_blank"
              rel="noopener noreferrer"
              title="WhatsApp"
            >
              <img className="h-12 w-12" alt="whatsapp" src={whatsapp} />
              <div className="text-center text-[16px] leading-[normal] tracking-[0] text-green-700">
                Whatsapp
              </div>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <a
              href={
                medsos.youtube ||
                "https://www.youtube.com/@jogjamadrasahdigital"
              }
              target="_blank"
              rel="noopener noreferrer"
              title="Youtube"
            >
              <img className="h-12 w-12" alt="youtube" src={youtube} />
              <div className="text-center text-[16px] leading-[normal] tracking-[0] text-green-700">
                Youtube
              </div>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <a
              href={
                medsos.instagram ||
                "https://www.instagram.com/jogjamadrasahdigital"
              }
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <img className="h-12 w-12" alt="instagram" src={instagram} />
              <div className="text-center text-[16px] leading-[normal] tracking-[0] text-green-700">
                Instagram
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sosmed;
