import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import CustomUploadAdapterPlugin from "../../../../components/admin/ckeditor/UploadAdapter";

const EditSambutan = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const navigate = useNavigate();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_w/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        toast.error(
          "Error Upload Gambar: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api_w/abouts/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setTitle(response.data.data?.title || "");
        setBody(response.data.data?.body || "");
        setType(response.data.data?.type || "");
        setImageUrl(response.data.data?.image_url || "");
        setVideoUrl(response.data.data?.video_url || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async () => {
    try {
      const data = {
        title: title,
        body: body,
        type: type,
        image_url: type === "image" ? imageUrl : "",
        video_url: type === "video" ? videoUrl : "",
      };

      const response = await axios.put(`/api_w/abouts/${id}`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      console.log(response.data);
      toast.success(`Berhasil Edit ${title}!`);
      navigate("/admin/sambutan");
    } catch (error) {
      toast.error(
        `Error Edit ${title}: ` +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleCancel = () => {
    navigate("/admin/sambutan");
  };

  return (
    <div className="container mx-auto p-5">
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [CustomUploadAdapterPlugin],
        }}
        data={body}
        onChange={(event, editor) => {
          const data = editor.getData();
          setBody(data);
        }}
      />
      <div className="mb-4">
        <label className="mt-4 text-md block font-bold text-gray-800 dark:text-white">
          Tipe
        </label>
        <select
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="">Pilih Tipe</option>
          <option value="image">Image</option>
          <option value="video">Video</option>
        </select>
      </div>
      {type === "image" && (
        <div className="mb-4">
          <label className="text-md block font-bold text-gray-800 dark:text-white">
            Gambar
          </label>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="image_url"
              className="mt-2 mb-2 h-36 w-36"
            />
          )}
          <input
            type="file"
            onChange={handleFileChange}
            className="dark:text-white"
          />
          <span className="ml-1 mt-0 block text-xs text-gray-600">
            *Max size: 5MB, Format: .png, .jpg, .jpeg, .svg, .webp
          </span>
        </div>
      )}
      {type === "video" && (
        <div className="mb-4">
          <label className="text-md block font-bold text-gray-800 dark:text-white">
            Video URL
          </label>
          <input
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      )}
      <div className="mt-4 flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-green-600 py-2 px-4 font-bold text-white"
          onClick={handleSubmit}
        >
          Edit
        </button>
      </div>
    </div>
  );
};
export default EditSambutan;
