import React, { useEffect, useState } from "react";
import CardCarousel from "./components/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Carousel = () => {
  const navigate = useNavigate();
  const [carousels, setCarousels] = useState([]);

  const handleTambahClick = () => {
    navigate("/admin/add-carousel");
  };

  useEffect(() => {
    const fetchCarouselsData = async () => {
      try {
        const response = await axios.get(`/api_w/carousels`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setCarousels(response.data.data);
      } catch (error) {
        toast.error(
          "Error fetching carousel data" +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchCarouselsData();
  }, []);

  const handleDelete = (deletedId) => {
    setCarousels((prevCarousels) =>
      prevCarousels.filter((item) => item.id !== deletedId)
    );
  };

  return (
    <div className="relative flex flex-wrap justify-center">
      <button
        onClick={handleTambahClick}
        className="absolute right-4 top-4 rounded bg-green-700 px-4 py-2 text-white hover:bg-green-500"
      >
        Tambah Carousel
      </button>
      <div className="mt-16 flex w-full flex-wrap justify-center">
        {carousels.map((item) => (
          <CardCarousel
            key={item.id}
            id={item.id}
            url={item.url.split(",")[0]}
            type={item.type}
            title={item.title}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
