import React, { useRef, useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function RememberMeCheckbox({ isChecked, handleCheckboxChange }) {
  return (
    <div className="mt-6 flex gap-1.5 text-sm md:flex-wrap">
      <div className="border-blue-80 h-[19px] w-5 shrink-0 self-start rounded border border-solid ">
        <input
          type="checkbox"
          id="ingatSaya"
          name="ingatSaya"
          className="hidden"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label
          htmlFor="ingatSaya"
          className="bg-transparant flex h-[19px] w-5 cursor-pointer items-center justify-center border border-solid border-blue-800"
        >
          {isChecked && <MdCheck className="h-6 w-6" />}
        </label>
      </div>
      <div>Ingat Saya</div>
    </div>
  );
}

function Login({ closePopup, onLoginSuccess }) {
  const rememberedEmail = localStorage.getItem("rememberedEmail") || "";
  const rememberedPassword = localStorage.getItem("rememberedPassword") || "";
  const [email, setEmail] = useState(rememberedEmail);
  const [password, setPassword] = useState(rememberedPassword);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    !!(rememberedEmail && rememberedPassword)
  );
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [useFetch, setUseFetch] = useState(true);
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      localStorage.removeItem("rememberedEmail");
      localStorage.removeItem("rememberedPassword");
    }
  };

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      closePopup();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError(false);
    setPasswordError(false);

    if (!email && !password) {
      setEmailError(true);
      setPasswordError(true);
      setLoading(false);
      return;
    } else if (!email) {
      setEmailError(true);
      setLoading(false);
      return;
    } else if (!password) {
      setPasswordError(true);
      setLoading(false);
      return;
    }

    const data = { email, password, isChecked };

    if (useFetch) {
      // Using Fetch Login Geschool
      fetch("/_api/main/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          return res.json();
        })
        .then((result) => {
          if (result.success) {
            window.location.href = result.redirect_uri;
            return;
          }

          if (result.error) {
            setPasswordError(true);
            setEmailError(true);
          }

          setLoading(false);
        })
        .catch((error) => {
          setPasswordError(true);
          setEmailError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Using Axios Login Dashboard Admin
      let data = JSON.stringify({
        username: email,
        password: password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_w/users/login`,
        headers: {
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        if (response.status === 200) {
          const { token, user } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("id", user.id);
          localStorage.setItem("role", user.role);
          setIsLoggedIn(true);
          if (user.role === "superadmin" || user.role === "admin") {
            navigate("/admin");
          } else {
            navigate("/");
            onLoginSuccess();
          }

          if (isChecked) {
            localStorage.setItem("rememberedEmail", email);
            localStorage.setItem("rememberedPassword", password);
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
          }
          toast.success("Berhasil Login");
        } else {
          toast.error("Gagal Login");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      onClick={handleOutsideClick}
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
    >
      <form
        onSubmit={handleSubmit}
        ref={popupRef}
        className="relative flex flex-col rounded-lg bg-green-200 px-6 py-2 text-green-800"
      >
        <div className="flex w-[280px] items-center justify-center self-center p-6 text-center text-2xl font-medium md:w-full">
          Silakan masuk menggunakan akun anda
        </div>
        <div className="mt-4 text-lg">Alamat Email</div>
        <input
          type="text"
          className={`text-black items-start justify-center rounded-[32.715px] bg-white px-5 py-4 text-base ${
            emailError ? "border-red-500" : ""
          } text-opacity-30 outline-none`}
          placeholder="Masukkan email / username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && (
          <div className="text-sm text-red-500">Email tidak ditemukan</div>
        )}

        <div className="mt-4 text-lg">Password</div>
        <div className="relative flex justify-between gap-5 rounded-[35.367px] bg-white px-4 py-3 text-base text-opacity-30 md:flex-wrap">
          <input
            type={showPassword ? "text" : "password"}
            className={`text-black w-full outline-none ${
              passwordError ? "border-red-500" : ""
            }`}
            placeholder="Masukkan Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="absolute right-0 top-1/2 -translate-y-1/2 transform"
            style={{
              marginRight: "16px",
              cursor: "pointer",
            }}
            onClick={() => setShowPassword((showPassword) => !showPassword)}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </IconContext.Provider>
          </span>
        </div>
        {passwordError && (
          <div className="text-sm text-red-500">Password tidak sesuai</div>
        )}

        <RememberMeCheckbox
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
        />

        <button
          type="submit"
          disabled={loading}
          className="mt-6 flex w-[225px] items-center justify-center self-center whitespace-nowrap rounded-[52.167px] bg-green-700 px-16 py-2.5 text-xl font-semibold text-white md:w-full"
        >
          {loading ? "Loading..." : "Masuk"}
        </button>
        <div className="mt-6 mb-2 text-center text-base">
          Belum punya akun?
          <br />
          Silakan hubungi admin
        </div>
        <div className="text-center mt-2">
          <button
            type="button"
            onClick={() => setUseFetch(!useFetch)}
            className="text-blue-500"
          >
            {useFetch
              ? "Switch to Login Dashboard"
              : "Switch to Login Geschool"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
