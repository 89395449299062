import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import logo from "../../../assets/img/logo-jmd.png";
import logo1 from "../../../assets/img/logo-kemenang.png";

function CardVisiMisi() {
  const [visi, setVisi] = useState("");
  const [titleVisi, setTitleVisi] = useState("");
  const [imageUrlVisi, setImageUrlVisi] = useState("");
  const [videoUrlVisi, setVideoUrlVisi] = useState("");
  const [misi, setMisi] = useState("");
  const [titleMisi, setTitleMisi] = useState("");
  const [imageUrlMisi, setImageUrlMisi] = useState("");
  const [videoUrlMisi, setVideoUrlMisi] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const visionResponse = await axios.get(`/api_w/abouts?title=Visi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const missionResponse = await axios.get(`/api_w/abouts?title=Misi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const vision = visionResponse.data.data[0];
        const mission = missionResponse.data.data[0];

        //data visi
        setTitleVisi(vision?.title || "Visi");
        setVisi(vision?.body || "Visi Jogja Madrasah Digital");
        setImageUrlMisi(mission?.image_url || "");
        setVideoUrlMisi(mission?.video_url || "");

        //data misi
        setTitleMisi(mission?.title || "Misi");
        setMisi(mission?.body || "Misi Jogja Madrasah Digital");
        setImageUrlVisi(vision?.image_url || "");
        setVideoUrlVisi(vision?.video_url || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchData();
  }, []);

  const renderVideo = (url) => {
    const youtubeMatch = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = url.match(/drive\.google\.com\/file\/d\/([^\/]+)\//);

    let videoUrl = url;
    if (youtubeMatch) {
      videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }

    return (
      <div className="flex justify-center items-center">
        <iframe
          className="md:w-[350px] h-[200px]"
          src={videoUrl}
          title="Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-xl">
        <span className="border-b-2 border-green-600 font-bold hover:cursor-pointer hover:text-green-700">
          Visi Misi
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="w-full px-5 py-3">
          <div className="flex justify-center items-center flex-row">
            <div className="w-full md:w-1/2 px-2 mb-4 flex justify-center items-center">
              {videoUrlVisi ? (
                renderVideo(videoUrlVisi)
              ) : imageUrlVisi ? (
                <img
                  src={imageUrlVisi}
                  alt="visi-image"
                  className="mb-4 md:h-[250px] w-auto"
                />
              ) : (
                <img
                  src={logo}
                  alt="visi-image"
                  className="mb-4 md:h-[250px] w-auto"
                />
              )}
            </div>

            <div className="w-full md:w-1/2 px-2 mb-4 flex justify-center items-center">
              {videoUrlMisi ? (
                renderVideo(videoUrlMisi)
              ) : imageUrlMisi ? (
                <img
                  src={imageUrlMisi}
                  alt="misi-image"
                  className="mb-4 md:h-[250px] w-auto"
                />
              ) : (
                <img
                  src={logo1}
                  alt="misi-image"
                  className="mb-4 md:h-[250px] w-auto"
                />
              )}
            </div>
          </div>

          <div className="mt-4">
            <h4 className="text-xl font-bold">{titleVisi || "Visi"}</h4>
            <div className="mt-2 text-gray-700">
              {parse(visi) || "Visi Jogja Madrasah Digital"}
            </div>
          </div>
          <div className="mt-4">
            <h4 className="text-xl font-bold">{titleMisi || "Misi"}</h4>
            <div className="mt-2 text-gray-700">
              {parse(misi) || "Misi Jogja Madrasah Digital"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardVisiMisi;
