import { Route, Routes, useLocation } from "react-router-dom";
import AdminLayout from "./layouts/admin/index";
import Home from "./views/user/Home";
import Login from "./components/user/Login";
import Register from "./components/user/Register";
import Berita from "./views/user/posts/Posts";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import DetailsPosts from "./views/user/posts/DetailsPosts";
import NotFound from "./components/user/NotFound";
import Foto from "./views/user/galeri/foto/Foto";
import Video from "./views/user/galeri/video/Video";
import Artikel from "./views/user/artikel/Artikel";
import VisiMisi from "./views/user/visi-misi/VisiMisi";
import Sambutan from "./views/user/sambutan/Sambutan";
import Kontak from "./views/user/kontak/Kontak";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("role");
          setIsLoggedIn(false);
          setUserRole(null);
          setShowLoginPopup(true);
          toast.warn("Session telah habis");
        } else {
          setIsLoggedIn(true);
          setUserRole(localStorage.getItem("role"));
          setShowLoginPopup(false);
        }
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("role");
        setIsLoggedIn(false);
        setUserRole(null);
        setShowLoginPopup(true);
        toast.error("Error decoding token");
      }
    } else {
      setIsLoggedIn(false);
      setUserRole(null);
      if (location.pathname.startsWith("/admin")) {
        setShowLoginPopup(true);
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route
          path="/admin/*"
          element={
            isLoggedIn &&
            (userRole === "superadmin" || userRole === "admin") ? (
              <AdminLayout />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/berita" element={<Berita />} />
        <Route path="/berita/:slug" element={<DetailsPosts />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/galeri-foto" element={<Foto />} />
        <Route path="/galeri-video" element={<Video />} />
        <Route path="/artikel" element={<Artikel />} />
        <Route path="/artikel/:slug" element={<DetailsPosts />} />
        <Route path="/visi-misi" element={<VisiMisi />} />
        <Route path="/sambutan" element={<Sambutan />} />
        <Route path="/kontak" element={<Kontak />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <ToastContainer theme="colored" />
      {showLoginPopup && <Login closePopup={() => setShowLoginPopup(false)} />}
    </>
  );
}

export default App;
