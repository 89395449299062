import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "user",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/api_w/users/register`, formData, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      })
      .then((response) => {
        toast.success("Daftar Akun Berhasil, Silakan Login", response.data);
        navigate("/login");
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.message);
      });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <form
        onSubmit={handleSubmit}
        className="relative flex flex-col rounded-lg bg-green-200 px-6 py-2 text-green-800"
      >
        <div className="flex w-[280px] items-center justify-center self-center p-6 text-center text-2xl font-medium md:w-full">
          Silakan masukkan data untuk daftar akun
        </div>
        <div className="mt-4 text-lg">Nama</div>
        <input
          type="text"
          id="name"
          name="name"
          className="text-black items-start justify-center rounded-[32.715px] bg-white px-3 py-2 text-base text-opacity-30 outline-none"
          placeholder="Masukkan nama"
          value={formData.name}
          onChange={handleChange}
        />
        <div className="mt-4 text-lg">Username</div>
        <input
          type="text"
          id="username"
          name="username"
          className="text-black items-start justify-center rounded-[32.715px] bg-white px-3 py-2 text-base text-opacity-30 outline-none"
          placeholder="Masukkan username"
          value={formData.username}
          onChange={handleChange}
        />
        <div className="mt-4 text-lg">Alamat Email</div>
        <input
          type="email"
          id="email"
          name="email"
          className="text-black items-start justify-center rounded-[32.715px] bg-white px-3 py-2 text-base text-opacity-30 outline-none"
          placeholder="Masukkan email"
          value={formData.email}
          onChange={handleChange}
        />

        <div className="mt-4 text-lg">Password</div>
        <div className="relative flex justify-between gap-5 rounded-[35.367px] bg-white px-3 py-2 text-base text-opacity-30 md:flex-wrap">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            className={"text-black w-full outline-none"}
            placeholder="Masukkan Password"
            value={formData.password}
            onChange={handleChange}
          />
          <span
            className="absolute right-0 top-1/2 -translate-y-1/2 transform"
            style={{
              marginRight: "16px",
              cursor: "pointer",
            }}
            onClick={() => setShowPassword((showPassword) => !showPassword)}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </IconContext.Provider>
          </span>
        </div>
        <button
          type="submit"
          className="mt-6 mb-4 flex w-[225px] items-center justify-center self-center whitespace-nowrap rounded-[52.167px] bg-green-700 px-16 py-2.5 text-xl font-semibold text-white md:w-full"
        >
          Daftar
        </button>
      </form>
    </div>
  );
};

export default Register;
