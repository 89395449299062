import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import logoJMD from "../../../assets/img/logo-jmd.png";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

function ListArtikel() {
  const [artikel, setArtikel] = useState([]);

  const defaultArtikel = {
    title: "Jogja Madrasah Digital",
    description: "Atikel Jogja Madrasah Digital.",
    date: "2024-08-15 09:58:35",
    image: logoJMD,
    slug: "jogja-madrasah-digital",
  };

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_w/posts`, {
          params: {
            // status: 1,
            category_id: 3,
          },
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        if (response.data.data.length === 0) {
          setArtikel([defaultArtikel]);
        } else {
          const filteredPosts = response.data.data.filter(
            (post) => post.status == 1
          );

          setArtikel(filteredPosts);
        }
      } catch (error) {
        toast.error(
          "Error fetching posts: " +
            (error.response?.data?.message || error.message)
        );
        setArtikel([defaultArtikel]);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(timeString).toLocaleTimeString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="">
      <div className="mb-4 flex items-center justify-between">
        <span className="border-b-2 border-green-500 font-bold hover:cursor-pointer hover:text-green-700">
          <h5 className="name-section text-[18px] font-bold">Artikel</h5>
        </span>
      </div>
      <div className="flex flex-wrap">
        {artikel.map((artikel) => (
          <div key={artikel.id} className="mb-2 w-full">
            <div className="card-artikel">
              <div className="card mb-4">
                <div className="flex flex-wrap shadow-lg rounded-xl">
                  <div className="w-full p-2 md:w-1/3">
                    <div className="card-body flex items-center justify-center">
                      <img
                        src={artikel.image}
                        className="h-auto w-auto transform transition-transform duration-300 hover:scale-110 rounded-md"
                        alt={artikel.title}
                      />
                    </div>
                  </div>
                  <div className="w-full p-2 md:w-2/3">
                    <div className="card-body">
                      <Link to={`/artikel/${artikel.slug}`}>
                        <h5 className="card-title-artikel text-[20px] font-bold hover:cursor-pointer hover:text-green-700">
                          {truncateText(artikel.title, 120)}
                        </h5>
                      </Link>
                      <div className="mt-2 flex items-center space-x-4 text-sm">
                        <div className="flex items-center">
                          <FaRegCalendarAlt className="mr-2 h-4 w-4 text-green-500" />
                          {formatDate(artikel.date)}
                        </div>
                        <div className="flex items-center">
                          <FaRegClock className="mr-2 h-4 w-4 text-green-500" />
                          {formatTime(artikel.date)} WIB
                        </div>
                      </div>
                      <p className="card-text-artikel mt-4 text-justify">
                        {parse(truncateText(artikel.description, 500))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListArtikel;
