import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditVideo = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();

  const handleCancel = () => {
    navigate("/admin/video");
  };

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api_w/galleries/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const video = response.data.data;
        setTitle(video.title);
        setVideoUrl(video.url);
      } catch (error) {
        toast.error(
          "Error fetching video data: " +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchVideoData();
  }, [id]);

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `/api_w/galleries/${id}`,
        {
          title,
          url: videoUrl,
          type: "video",
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data);
      toast.success("Berhasil Edit Video!");
      navigate("/admin/video");
    } catch (error) {
      toast.error(
        "Error Edit Video: " + (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          id="title"
          name="title"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="Judul"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold text-gray-800 dark:text-white">
          URL Video
        </label>
        <input
          type="text"
          id="videoUrl"
          name="videoUrl"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="URL Video"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
        />
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-green-600 py-2 px-4 font-bold text-white"
          onClick={handleSubmit}
        >
          Edit
        </button>
      </div>
    </div>
  );
};
export default EditVideo;
