import React, { useState } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import EditCategories from "./EditCategories";

const TableCategories = ({ categories, fetchCategories }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const handleEditClick = (id) => {
    setSelectedCategoryId(id);
    setShowEditPopup(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteCategoryId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `/api_w/postcategories/${deleteCategoryId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      toast.success("Berhasil Hapus Kategori!");
      fetchCategories();
    } catch (error) {
      toast.error(
        "Error Hapus Kategori: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div>
      <table className="w-full bg-white">
        <thead className="bg-green-700 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="py-3 px-6 text-center">No</th>
            <th className="py-3 px-6 text-center">Kategori</th>
            <th className="py-3 px-6 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {categories.map((item, index) => (
            <tr
              key={index}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="whitespace-nowrap py-3 px-6 text-center">
                {index + 1}
              </td>
              <td className="py-3 px-6 text-left">{item.name}</td>
              <td className="py-3 px-6 text-center">
                <div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 transform text-red-500 hover:scale-110 hover:text-red-600"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showEditPopup && (
        <EditCategories
          onClose={() => setShowEditPopup(false)}
          categoryId={selectedCategoryId}
          fetchCategories={fetchCategories}
        />
      )}
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Kategori
            </h2>
            <p className="mb-4">
              Apakah anda yakin akan menghapus kategori ini?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 py-2 px-4 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 py-2 px-4 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCategories;
