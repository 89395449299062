import React, { useEffect, useState } from "react";
import logoJMD from "../../assets/img/logo-jmd.png";
import logokemenag from "../../assets/img/logo-kemenang.png";
import { Link } from "react-router-dom";
import Ig from "../../assets/img/instagram.png";
import Yt from "../../assets/img/youtube.png";
import Wa from "../../assets/img/WA.png";
import axios from "axios";
import { toast } from "react-toastify";

const Footer = () => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get("/api_w/logo", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const logoData = response.data.data || [];

        // Default logo
        const defaultLogos = [
          { id: 1, url: logokemenag },
          { id: 2, url: logoJMD },
        ];

        // Check if the API response contains logos with ID 1 and 2
        const logosDefaults = defaultLogos.map((defaultLogo) => {
          const dataLogo = logoData.find((logo) => logo.id === defaultLogo.id);
          return dataLogo || defaultLogo;
        });

        setLogos(logosDefaults);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setLogos([
          { id: 1, url: logokemenag },
          { id: 2, url: logoJMD },
        ]);
      }
    };

    fetchLogoData();
  }, []);

  return (
    <div className="bg-green-700 p-2 text-white">
      <div className="container mx-auto px-6 py-2 md:block hidden">
        <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-2 sm:gap-y-10 md:text-left lg:grid-cols-5">
          <div className="sm:col-span-2">
            <div className="flex justify-center md:justify-start">
              {logos.map((logo) => (
                <img
                  key={logo.id}
                  className="h-20 mr-2"
                  src={logo.url}
                  alt="Logo"
                />
              ))}
              {/* <img className="h-20" src={logokemenag} alt="Logo Kemenag" />
              <img className="h-20" src={logoJMD} alt="Company Logo" /> */}
            </div>
            <div className="mt-3">
              <p>Jl. Sukonandi No.8, Semaki, Kec. Umbulharjo,</p>
              <p>Kota Yogyakarta, Daerah Istimewa Yogyakarta,</p>
              <p>55166</p>
            </div>
            <p className="my-3 font-bold mt-8">Ikuti Kami</p>
            <p className="flex items-center justify-center md:justify-start">
              <Link to="https://www.instagram.com/jogjamadrasahdigital">
                <img className="h-8 w-8 mr-2" src={Ig} alt="instagram" />
              </Link>
              <Link to="https://youtube.com/@jogjamadrasahdigital">
                <img className="h-8 w-8 mr-2" src={Yt} alt="youtube" />
              </Link>
            </p>
          </div>

          <div>
            <p className="font-bold text-gray-100 mt-20">Program</p>

            <div className="mt-5 flex flex-col space-y-2">
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Madrasah Cerdas Mandiri</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Orang Tua Cerdas Mandiri</Link>
              </p>
            </div>
          </div>

          <div>
            <p className="font-bold text-gray-100 mt-20">Bantuan dan Panduan</p>

            <div className="mt-5 flex flex-col space-y-2">
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Kebijakan Privasi</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Syarat & Ketentuan</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">FAQ</Link>
              </p>
            </div>
          </div>

          <div>
            <p className="font-bold text-gray-100 mt-20">Hubungi Kami</p>

            <div className="mt-5 flex flex-col space-y-2">
              <div>
                Email:
                <Link
                  to=""
                  className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700"
                >
                  {" "}
                  kanwildiy@kemenag.go.id
                </Link>
              </div>
              <div className="flex items-start">
                <img className="h-7 w-7 mr-2" src={Wa} alt="whatsapp" />
                <div className="flex flex-col space-y-1">
                  <Link
                    to="https://wa.me/6287739532870"
                    target="_blank"
                    className="transition-colors duration-300 hover:text-blue-800 hover:underline dark:hover:text-blue-700"
                  >
                    +6287739532870
                  </Link>
                  <Link className="transition-colors duration-300 hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                    +6281226264038
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="font-sans text-center md:mt-6 text-sm p-2 md:p-0">
        &copy; {1900 + new Date().getYear()} Jogja Madrasah Digital Kanwil
        Kemenag DIY . All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
