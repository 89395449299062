import React from "react";
import { useNavigate } from "react-router-dom";
import TableUser from "./components/TableUser";

function User() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const handleTambahUser = () => {
    navigate("/admin/add-user");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justif-between">
        {role === "superadmin" && (
          <div>
            <button
              onClick={handleTambahUser}
              className="mr-2 rounded bg-green-700 px-4 py-2 text-white hover:bg-green-500"
            >
              Tambah User
            </button>
          </div>
        )}
      </div>
      <TableUser />
    </div>
  );
}

export default User;
