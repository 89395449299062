import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import logoJMD from "../../../assets/img/logo-jmd.png";
import parse from "html-react-parser";

function CardSambutan() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const fetchDataSambutan = async () => {
      try {
        const response = await axios.get("/api_w/abouts?title=sambutan", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const sambutan = response.data.data[0];
        if (sambutan) {
          setTitle(sambutan.title);
          setBody(sambutan.body);
          setType(sambutan.type || "image");
          setImageUrl(sambutan.image_url);
          setVideoUrl(sambutan.video_url);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchDataSambutan();
  }, []);

  const renderVideo = (url) => {
    const youtubeMatch = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = url.match(/drive\.google\.com\/file\/d\/([^\/]+)\//);

    let videoUrl = url;
    if (youtubeMatch) {
      videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }
    return (
      <div className="flex justify-center">
        <iframe
          className="w-80 h-48 rounded-md"
          src={videoUrl}
          title="video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const renderContent = () => {
    if (type === "image") {
      return imageUrl ? (
        <img
          src={imageUrl}
          alt={title || "sambutan"}
          className="h-[250px] w-auto rounded-md"
        />
      ) : (
        <img
          src={logoJMD}
          alt="Logo JMD"
          className="h-[250px] w-auto rounded-md"
        />
      );
    } else if (type === "video") {
      return videoUrl ? (
        renderVideo(videoUrl)
      ) : (
        <img
          src={logoJMD}
          alt="logo JMD"
          className="h[250px] w-auto rounded-md"
        />
      );
    } else {
      return (
        <img
          src={logoJMD}
          alt="logo JMD"
          className="h-[250px] w-auto rounded-md"
        />
      );
    }
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-xl">
        <span className="border-b-2 border-green-600 font-bold hover:cursor-pointer hover:text-green-700">
          Jogja Madrasah Digital is....
        </span>
      </h3>
      <div className="card mt-4 flex flex-col border px-3 py-3 shadow-md md:flex-row">
        <div className="md:mr-4">
          <div className="flex items-center justify-center text-center">
            {renderContent()}
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between overflow-hidden">
          <div className="mt-4 w-[225px] break-words text-justify md:mt-0 md:w-auto">
            {parse(body || "Selamat datang di website Jogja Madrasah Digital")}
          </div>
          <div className="mt-4 flex items-end justify-end">
            <div className="text-left">
              <p className="mt-2 font-bold">Jogja Madrasah Digital</p>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSambutan;
