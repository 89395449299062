import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Ig from "../../../assets/icon-sosmed/ig.png";
import Whatsapp from "../../../assets/icon-sosmed/wa.png";
import Maps from "./Maps";

function CardKontak() {
  const [contact, setContact] = useState([]);

  useEffect(() => {
    const fetchKontak = async () => {
      try {
        const response = await axios.get(`/api_w/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const kontak = response.data.data[0];
        setContact(kontak);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchKontak();
  }, []);

  return (
    <div className="mb-12">
      <h3 className="headers text-center text-[20px]">
        <span className="border-b-2 border-green-600 font-bold hover:cursor-pointer hover:text-green-700">
          Kontak {contact.school_name || "Jogja Madrasah Digital"}
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg">
        <div className="py-2 w-full">
          <Maps />
        </div>
      </div>
      <br></br>
      <h3 className="headers text-center text-[20px]">
        <span className="border-b-2 border-green-600 font-bold hover:cursor-pointer hover:text-green-700">
          Hubungi Kami
        </span>
      </h3>
      <div className="mt-2 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="w-full px-6 py-4">
          <div className="flex justify-center">
            <div className="flex items-center space-x-4">
              <a
                href={
                  `https://wa.me/${contact.whatsapp}` ||
                  "https://wa.me/0811679689"
                }
                target="_blank"
                rel="noopener noreferrer"
                title="WhatsApp"
              >
                <img className="h-12 w-12 mr-2" src={Whatsapp} alt="whatsapp" />
              </a>
              <a
                href={
                  contact.instagram ||
                  "https://www.instagram.com/jogjamadrasahdigital"
                }
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <img className="h-12 w-12 mr-2" src={Ig} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardKontak;
