import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import axios from "axios";
import { toast } from "react-toastify";
import Login from "../Login";

const Carousel = () => {
  const [carousels, setCarousels] = useState([]);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

  const toggleLoginPopup = () => {
    setIsLoginOpen(!isLoginOpen);
  };

  const closeLoginPopup = () => {
    setIsLoginOpen(false);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    closeLoginPopup();
  };

  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await axios.get(`/api_w/carousels`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setCarousels(response.data.data);
      } catch (error) {
        toast.error(
          "Error fetching carousels: " +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchCarousels();
  }, []);

  const textStyle = {
    color: "#006316",
    textShadow:
      "-1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff, 0 0 3px #006316",
  };

  const renderMedia = (item) => {
    if (item.type === "image") {
      return (
        <>
          <img
            className="hidden md:block h-auto w-[100%]"
            src={item.url.split(",")[0]}
            alt={item.title}
            loading="lazy"
          />
          <img
            className="block md:hidden h-auto w-[100%]"
            src={item.url.split(",")[1]}
            alt={item.title}
            loading="lazy"
          />
        </>
      );
    } else if (item.type === "video") {
      const youtubeMatch = item.url.match(
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
      );
      const gdriveMatch = item.url.match(
        /drive\.google\.com\/file\/d\/([^\/]+)\//
      );

      let videoUrl = item.url;
      if (youtubeMatch) {
        videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}?autoplay=1&mute=1&loop=1&playlist=${youtubeMatch[1]}`;
      } else if (gdriveMatch) {
        videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview?autoplay=1&mute=1&loop=1&playlist-${gdriveMatch[1]}`;
      }

      return (
        <iframe
          className="h-auto w-[100%] pointer-events-none"
          src={videoUrl}
          title={item.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }
    return null;
  };

  return (
    <div className="relative mt-16 bg-cover bg-center bg-no-repeat overflow-hidden">
      <Swiper
        style={{
          "--swiper-pagination-color": "#fff",
          "--swiper-navigation-color": "#fff",
          "--swiper-navigation-size": "40px",
          fontWeight: "bold",
        }}
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={{
          clickable: true,
        }}
      >
        {carousels.map((item) => (
          <SwiperSlide key={item.id} className="relative z-10">
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="relative z-10 pointer-events-auto"
            >
              {renderMedia(item)}
            </a>
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute inset-0 flex justify-start p-2 md:p-6 z-10 pointer-events-none items-start">
        <div className="text-white text-left">
          <p className="text-md md:text-2xl font-bold" style={textStyle}>
            Selamat Datang kembali di
          </p>
          <p className="text-xl md:text-7xl font-bold">
            <span style={textStyle}>JOGJA MADRASAH DIGITAL</span>
          </p>
          {!isLoggedIn && (
            <button
              onClick={toggleLoginPopup}
              type="button"
              className="mt-4 relative z-30 rounded-full text-center p-2 py-1 px-4 md:py-2 md:px-6 text-white bg-[#006316] hover:bg-green-600 font-bold md:text-lg text-md border pointer-events-auto"
            >
              Login
            </button>
          )}
        </div>
      </div>
      {/* Popup Login */}
      {isLoginOpen && (
        <Login
          closePopup={closeLoginPopup}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
    </div>
  );
};

export default Carousel;
