import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

function DetailsFoto({ photo, onClose }) {
  const [firstImage, ...otherImages] = photo.url.split(",");

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat("id-ID", options).format(
      new Date(dateString)
    );
  };

  return (
    <div className="relative bg-white p-4 rounded-lg max-w-lg mx-4">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
      >
        <IoMdClose className="h-5 w-5" />
      </button>
      <div
        className="max-h-[80vh] mt-3"
        style={{
          overflowY: "auto",
          paddingRight: "17px",
          scrollbarWidth: "thin",
        }}
      >
        <h2 className="text-xl font-bold text-green-800 mb-2 flex justify-center">
          {photo.title}
        </h2>
        <div className="flex items-center justify-center mb-4">
          <FaCalendarAlt className="h-4 w-4 text-green-600 mr-2" />
          <p className="text-xs text-green-800 font-semibold">
            {formatDate(photo.createdAt)}
          </p>
        </div>
        {firstImage && (
          <img
            className="w-full h-64 object-cover rounded-lg mb-4"
            src={firstImage}
            alt={photo.title}
          />
        )}
        {otherImages.length > 0 && (
          <div className="grid grid-cols-2 gap-4">
            {otherImages.map((url, index) => (
              <img
                key={index}
                className="w-full h-40 object-cover rounded-lg"
                src={url}
                alt={`foto ${index + 2}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default DetailsFoto;
