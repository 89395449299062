import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import FormSchool from "./components/Form";
import ButtonSchool from "./components/Buttons";

const ContactSchool = () => {
  const [schoolContact, setSchoolContact] = useState([]);
  const [update, setUpdate] = useState(false);
  const [originalProfile, setOriginalProfile] = useState({
    school_name: "Jogja Madrasah Digital",
    address: "",
    instagram: "",
    whatsapp: "",
    youtube: "",
    link_maps: "",
    coordinates: "",
  });

  useEffect(() => {
    const fetchSchoolContact = async () => {
      try {
        const response = await axios.get(`/api_w/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const profile = response.data.data[0] || originalProfile;
        setSchoolContact(profile);
        setOriginalProfile(profile);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchSchoolContact();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (update) {
      try {
        const config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `/api_w/medsos/${schoolContact.id}`,
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
          data: JSON.stringify(schoolContact),
        };

        const response = await axios.request(config);
        console.log(response.data);
        toast.success("Berhasil Edit Media Sosial Sekolah!");
        const updatedSchoolContact = await axios.get(`/api_w/medsos`);
        setSchoolContact(updatedSchoolContact.data.data[0]);
      } catch (error) {
        toast.error(
          "Error Edit Media Sosial Sekolah: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
    setUpdate((prev) => !prev);
  };

  const handleCancel = () => {
    setSchoolContact(originalProfile);
    setUpdate(false);
  };

  return (
    <div className="relative">
      {!update && (
        <button
          type="button"
          className="absolute top-0 right-6 m-4 flex rounded-md bg-green-700 hover:bg-green-600 px-3 py-2 text-white "
          onClick={() => setUpdate(true)}
        >
          Edit Media Sosial
        </button>
      )}
      <div className="p-10">
        <FormSchool
          schoolContact={schoolContact}
          handleChange={handleChange}
          update={update}
        />
        <ButtonSchool
          update={update}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
};
export default ContactSchool;
