import React, { useEffect, useState } from "react";
import Header from "../../components/user/Header";
import EducationWebsite from "../../components/user/home/EducationWebsite";
import BeritaTerbaru from "../../components/user/home/BeritaTerbaru";
import Footer from "../../components/user/Footer";
import Carousel from "../../components/user/home/Carousel";
// import Testimoni from "../../components/user/home/Testimoni";
import Sosmed from "../../components/user/home/Sosmed";
// import Media from "../../components/user/home/Media";
import Presensi from "../../components/user/home/Presensi";
import Learn from "../../components/user/home/Learn";
import Evaluation from "../../components/user/home/Evaluation";
import FolderDrive from "../../components/user/home/FolderDrive";
import VisiMisi from "../../components/user/home/VisiMisi";
import GaleriFoto from "../../components/user/home/GaleriFoto";
import GaleriVideo from "../../components/user/home/GaleriVideo";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import logoJMD from "../../assets/img/logo-jmd.png";
import axios from "axios";
import { toast } from "react-toastify";

function Home() {
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);

  const defaultPhotos = [
    {
      url: logoJMD,
      createdAt: "Kamis, 1 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
    {
      url: logoJMD,
      createdAt: "Jumat, 2 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
    {
      url: logoJMD,
      createdAt: "Jumat, 2 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
    {
      url: logoJMD,
      createdAt: "Sabtu, 3 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
    {
      url: logoJMD,
      createdAt: "Sabtu, 3 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
  ];

  const defaultVideos = [
    {
      url: "https://www.youtube.com/embed/QetUSIcVFkM?si=HO9yE_p6UF9fTxLC",
      createdAt: "Jumat, 2 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
    {
      url: "https://www.youtube.com/embed/qhNE9THdIDk?si=fyrKdztkc_3tVkeN",
      createdAt: "Jumat, 2 Agustus 2024",
      title: "Jogja Madrasah Digital",
    },
  ];

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api_w/galleries?type=video`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Jika data dari API kosong, gunakan data default
        if (response.data.data && response.data.data.length > 0) {
          setVideos(response.data.data);
        } else {
          setVideos(defaultVideos);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setVideos(defaultVideos);
      }
    };

    fetchVideoData();
  }, []);

  useEffect(() => {
    const fetchPhotoData = async () => {
      try {
        const response = await axios.get("/api_w/galleries?type=image", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Jika data dari API kosong, gunakan data default
        if (response.data.data && response.data.data.length > 0) {
          setPhotos(response.data.data);
        } else {
          setPhotos(defaultPhotos);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setPhotos(defaultPhotos);
      }
    };

    fetchPhotoData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  return (
    <>
      <Header />
      <Carousel />
      <BeritaTerbaru />
      <VisiMisi />
      <div className="mt-8">
        <GaleriFoto photos={photos} />
      </div>
      {/* Galeri Video */}
      <div className="flex flex-col items-center mt-8">
        <div className="text-3xl font-bold mb- text-green-700 mb-4">
          Galeri Video
        </div>
        <div className="flex flex-wrap justify-center">
          {videos.slice(0, 3).map((video, index) => (
            <GaleriVideo
              key={index}
              videoUrl={video.url}
              createdAt={formatDate(video.createdAt)}
              title={video.title}
            />
          ))}
        </div>
        <div className="mt-4 flex items-center justify-center">
          <Link
            to={"/galeri-video"}
            className="inline-flex items-center px-4 py-2 text-white bg-green-600 rounded-[10px] shadow-lg hover:bg-green-700 transition duration-300"
          >
            Lihat Selengkapnya
            <IoMdArrowForward className="ml-1 mt-1" />
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <Sosmed />
      </div>
      {/* <Media /> */}
      <div className="mt-5">
        <Presensi />
      </div>
      <Learn />
      <Evaluation />
      <div className="mt-5">
        <FolderDrive />
      </div>
      <div className="mt-5">
        <EducationWebsite />
      </div>
      {/* <Testimoni /> */}
      <Footer />
    </>
  );
}

export default Home;
