import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  MdOutlineAccessTimeFilled,
  MdPerson,
  MdRemoveRedEye,
} from "react-icons/md";
import { toast } from "react-toastify";
import ShareSosmed from "./Share";
import { Helmet } from "react-helmet-async";

function Details() {
  const { slug } = useParams();
  const [detail, setDetail] = useState("");

  const fetchDetail = async () => {
    try {
      const response = await axios.get(`/api_w/posts?slug=${slug}`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setDetail(response.data.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchDetail();

    const intervalId = setInterval(fetchDetail, 1000);

    return () => clearInterval(intervalId);
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateDescription = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const truncatedDescription = truncateDescription(detail.description, 50);

  return (
    <div className="relative flex flex-col">
      <Helmet>
        <title>{`Jogja Madrasah Digital | ${detail.title}`}</title>
        <meta name="description" content={truncatedDescription} />
        <meta name="keywords" content="Jogja Madrasah Digital" />
        <meta
          property="og:title"
          content={`Jogja Madrasah Digital | ${detail.title}`}
        />
        <meta property="og:description" content={truncatedDescription} />
        <meta property="og:image" content={detail.image} />
        <meta property="og:image:size" content="300" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content={detail.PostCategory?.name} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`Jogja Madrasah Digital | ${detail.title}`}
        />
        <meta name="twitter:description" content={truncatedDescription} />
        <meta name="twitter:image" content={detail.image} />
        <meta name="twitter:site" content={window.location.href} />
      </Helmet>
      <h2 className="text-[32px] font-bold">{detail.title}</h2>
      <div className="my-2 flex items-center">
        <MdOutlineAccessTimeFilled className="mr-1 h-4 w-4 text-green-600" />
        <span className="text-sm text-gray-400">{formatDate(detail.date)}</span>
        <MdPerson className="ml-2 mr-1 h-4 w-4 text-green-600" />
        <span className="text-sm text-gray-400">{detail.User?.name}</span>
        <MdRemoveRedEye className="ml-2 mr-1 h-4 w-4 text-green-600" />
        <span className="text-sm text-gray-400">{detail.views}</span>
      </div>
      <div className="mt-4 flex items-center justify-center">
        <img
          className="h-auto w-full rounded-md"
          src={detail.image || "https://placehold.co/600x400?text=No-Image"}
          alt={detail.title}
        />
      </div>
      <div className="mt-4 text-justify">
        {typeof detail.description === "string"
          ? parse(detail.description)
          : detail.description}
      </div>
      <ShareSosmed />
    </div>
  );
}

export default Details;
